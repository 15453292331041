// src/components/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ setIsSidebarOpen, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      className="logo"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link to="/">
        <img src="https://unhappy-scarlet-deer.myfilebase.com/ipfs/QmQs6uRZVkYYujmwAopBDdZw6zhscch4Q6pn5MPrTEXBmv" alt="Logo" />
      </Link>
    </div>
  );
};

export default Logo;
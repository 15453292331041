// src/components/CategoryList.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import CategoryCard from './CategoryCard';
import { collection, getDocs } from 'firebase/firestore';
import ProgressBar from './ProgressBar';
import useAdSense from '../utils/useAdSense';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useAdSense(); // Inject the AdSense script

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'bots'));
        const categoryList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoryList);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCategories = searchTerm.length >= 3
    ? categories.filter(category =>
        category.title && category.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : categories;

  if (loading) {
    return <ProgressBar loading={loading} />;
  }

  return (
    <>
      <div className="banner-home"></div>
      <div className="container">
        <div className="search-section">
          <input
            type="text"
            placeholder="Rechercher une catégorie..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
          />
        </div>
        <div className="category-list">
          {filteredCategories.length === 0 ? (
            <p>Aucune catégorie trouvée.</p>
          ) : (
            filteredCategories.map(category => (
              <CategoryCard key={category.id} {...category} />
            ))
          )}
        </div>
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-format="fluid"
          data-ad-layout-key="-gw-3+1f-3d+2z"
          data-ad-client="ca-pub-9001874765849215"
          data-ad-slot="1931286500"></ins>
      </div>
    </>
  );
};

export default CategoryList;
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCgCXzDq7YF3BBZwhkmLZAjnMmGkOe9i2k",
    authDomain: "mistral-bots-c86cd.firebaseapp.com",
    databaseURL: "https://mistral-bots-c86cd-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mistral-bots-c86cd",
    storageBucket: "mistral-bots-c86cd.firebasestorage.app",
    messagingSenderId: "247396002613",
    appId: "1:247396002613:web:31f01c84aa1ebfd7cb3a7c",
    measurementId: "G-NP9V3LNH2M"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, analytics };
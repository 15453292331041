import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';

const MentionsLegales = () => {
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    const fetchPrivacy = async () => {
      console.log('Fetching privacy data...');
      const docRef = doc(db, 'config', 'client');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        setPrivacy(docSnap.data().privacy);
      } else {
        console.log('No such document!');
      }
    };

    fetchPrivacy();
  }, []);

  return (
    <>
      <div className="banner-home"></div>
      <div className="container">
        <div className="mentions-legales">
          <div dangerouslySetInnerHTML={{ __html: privacy }} />
        </div>
      </div>
    </>
  );
};

export default MentionsLegales;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { ensureUniqueId, getUniqueId } from '../utils/uniqueId';

// Création du contexte
const UniqueIdContext = createContext(null);

// Fournisseur du contexte
export const UniqueIdProvider = ({ children }) => {
  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    ensureUniqueId();
    setUniqueId(getUniqueId());
  }, []);

  return (
    <UniqueIdContext.Provider value={uniqueId}>
      {children}
    </UniqueIdContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useUniqueId = () => useContext(UniqueIdContext);

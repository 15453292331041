import React from 'react';
import { Link } from 'react-router-dom';

const CategoryCard = ({ id, title, image }) => {
  return (
    <div className="category-card">
      <Link to={`/category/${id}`}>
        <img src={image} alt={title} className="category-image" />
        <h3 className="category-title">{title}</h3>
      </Link>
    </div>
  );
};

export default CategoryCard;
if (!crypto.randomUUID) {
  crypto.randomUUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };
}

export const generateUniqueId = () => {
  const uniqueId = crypto.randomUUID();
  localStorage.setItem('uniqueId', uniqueId);
  return uniqueId;
};

export const getUniqueId = () => {
  return localStorage.getItem('uniqueId');
};

export const ensureUniqueId = () => {
  if (!getUniqueId()) {
    generateUniqueId();
  }
};
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import ProgressBar from './ProgressBar';
import { useUniqueId } from '../components/UniqueIdContext';

const CategoryPage = () => {
  const [prompt, setPrompt] = useState('');
  const [tutorial, setTutorial] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [category, setCategory] = useState(null);
  const [error, setError] = useState(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const { id } = useParams();
  const imageUrl = 'https://unhappy-scarlet-deer.myfilebase.com/ipfs/Qme3eRfjHLrmqVFxBbGkmnEthQZ3EcZ198BsQdRJe4uG2G';
  const uniqueId = useUniqueId();
  const tutorialWelcomeSectionRef = useRef(null);
  const tutorialSectionRef = useRef(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const docRef = doc(db, 'bots', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCategory({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Category not found');
        }
      } catch (error) {
        setError('Error fetching category');
        console.error("Error fetching category: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategory();
  }, [id]);

  useEffect(() => {
    if (category) {
      document.title = 'SOZKE - ' + category.title;
      updateMetaTag("description", category.prediction, true);
      updateMetaTag("og:title", 'SOZKE - ' + category.title, true);
      updateMetaTag("og:description", category.prediction, true);
      updateMetaTag("og:url", `https://sozke.fr/category/${category.id}`, true);
      updateMetaTag("twitter:title", 'SOZKE - ' + category.title);
      updateMetaTag("twitter:description", category.prediction);
      updateMetaTag("twitter:image", `https://sozke.fr/category/${category.id}`);
    }
  }, [category]);

  function updateMetaTag(name, content, isProperty = false) {
    let metaTag = document.querySelector(isProperty ? `meta[property='${name}']` : `meta[name='${name}']`);
    if (metaTag) {
      metaTag.setAttribute("content", content);
    } else {
      metaTag = document.createElement("meta");
      metaTag.setAttribute(isProperty ? "property" : "name", name);
      metaTag.setAttribute("content", content);
      document.head.appendChild(metaTag);
    }
  }

  useEffect(() => {
    if (autoScroll) {
      if (tutorial) {
        if (tutorialSectionRef.current) {
          tutorialSectionRef.current.scrollTop = tutorialSectionRef.current.scrollHeight;
        }
      } else {
        if (tutorialWelcomeSectionRef.current) {
          tutorialWelcomeSectionRef.current.scrollTop = tutorialWelcomeSectionRef.current.scrollHeight;
        }
      }
    }
  }, [tutorial, autoScroll]);

  useEffect(() => {
    const sendWelcomeMessage = async () => {
      try {
        const response = await fetch('https://backend-service-858437566769.europe-west1.run.app/api/welcome', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt: "Bonjour.",
            id: category.id,
            uniqueId: uniqueId
          }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if (!response.body) {
          throw new Error('ReadableStream not yet supported in this browser.');
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let result = '';
        let done = false;
        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split('\n').filter(line => line.trim() !== '');
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const dataStr = line.slice(6);
                if (dataStr.trim() === "[DONE]") {
                  continue;
                }
                try {
                  if (dataStr.trim().startsWith('{') && dataStr.trim().endsWith('}')) {
                    const data = JSON.parse(dataStr);
                    const content = data.choices[0].delta.content || '';
                    result += content;
                    setTutorial(prev => prev + content);
                  }
                } catch (error) {
                  console.error("JSON parsing error:", error);
                }
              }
            }
          }
        }
        // Ajouter deux sauts de ligne après chaque réponse complète
        setTutorial(prev => prev + '\n\n');
        try {
          if (result === '') {
            setTutorial("Désolé, je ne suis pas disponible pour le moment.");
          }
        } catch (error) {
          console.error("JSON parsing error:", error);
        }
      } catch (error) {
        console.error("There was an error sending the welcome message!", error);
      }
    };
    if (category) {
      sendWelcomeMessage();
    }
  }, [category, uniqueId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingResponse(true);
    setError('');
    setTutorial(prev => prev + `\n\`\`\`\n${prompt}\n\`\`\`\n`);
    try {
      const response = await fetch('https://backend-service-858437566769.europe-west1.run.app/api/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt, id: category.id, uniqueId: uniqueId }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (!response.body) {
        throw new Error('ReadableStream not yet supported in this browser.');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      let done = false;
      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n').filter(line => line.trim() !== '');
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const dataStr = line.slice(6);
              if (dataStr.trim() === "[DONE]") {
                continue;
              }
              try {
                if (dataStr.trim().startsWith('{') && dataStr.trim().endsWith('}')) {
                  const data = JSON.parse(dataStr);
                  const content = data.choices[0].delta.content || '';
                  result += content;
                  setTutorial(prev => prev + content);
                }
              } catch (error) {
                console.error("JSON parsing error:", error);
              }
            }
          }
        }
      }
      // Ajouter deux sauts de ligne après chaque réponse complète
      setTutorial(prev => prev + '\n\n');
      try {
        if (result === '') {
          setTutorial("Désolé, je suis actuellement en maintenance. Merci de revenir un peu plus tard.");
        }
      } catch (error) {
        console.error("JSON parsing error:", error);
      }
    } catch (error) {
      console.error("There was an error!", error);
      setError('Failed to fetch tutorial. Please try again.');
    } finally {
      setLoadingResponse(false);
      setPrompt('');
    }
  };

  if (loading) {
    return <ProgressBar loading={loading} />;
  }

  if (error) {
    return (
      <>
        <div className="banner">
          <div className="navigation"><Link to={`/`}><i className="fa fa-arrow-circle-left fa-2x"></i></Link></div>
          <img src={imageUrl} alt="Catégorie non trouvée" className="banner" />
        </div>
        <div className="container">
          <div className="category-page">Catégorie non trouvée</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="banner">
        <div className="navigation"><Link to={`/`}><i className="fa fa-arrow-circle-left fa-2x"></i></Link></div>
        <img src={category.image} alt={category.title} className="banner" />
      </div>
      <div className="container">
        <div className="category-page">
          <h2>{category.title}</h2>
          {/*<label>
            <input
              type="checkbox"
              id="scrollToCheckbox"
              checked={autoScroll}
              onChange={() => setAutoScroll(!autoScroll)}
            />
            Défilement automatique
          </label>
          */}
          {!tutorial && (
            <section className="tutorial-welcome-section" ref={tutorialWelcomeSectionRef}>
              <div className="chat-container">
                {/*
                <img src={category.image} alt="Robot Avatar" className="avatar" />
                <div className="chat-bubble">
                  category.description
                </div>
                */}
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </section>
          )}
          {tutorial && (
            <section className="tutorial-section" ref={tutorialSectionRef}>
              <ReactMarkdown>{tutorial}</ReactMarkdown>
            </section>
          )}
          <div>
            <form onSubmit={handleSubmit} className="App-form">
              {/*<textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder={category.description}
                className="App-textarea"
                rows={3}
                cols={10}
                required={true}
                disabled={loadingResponse}
              />*/}
              <input value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder={category.description}
                className="App-input"
                required={true}
                disabled={loadingResponse}></input>
              <button type="submit" className="button button-primary" disabled={loadingResponse}>
                {loadingResponse ? 'Réponse en cours...' : 'Demander'}
              </button><br />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryPage;

// src/App.js
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import CategoryList from './components/CategoryList';
import CategoryPage from './components/CategoryPage';
import MentionsLegales from './components/MentionsLegales';
import { UniqueIdProvider } from './components/UniqueIdContext'; // Import du contexte
import ChatbotAssistant from './components/ChatbotAssistant';
import Logo from './components/Logo';
import { analytics } from './firebase'; // Import the analytics object
import { logEvent } from 'firebase/analytics'; // Import the logEvent function
import './App.css';

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const sidebarRef = useRef(null);
  const logoRef = useRef(null);
  let timeoutId;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsSidebarOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      if (!isLogoHovered) {
        setIsSidebarOpen(false);
      }
    }, 50);
  };

  const handleLogoMouseEnter = () => {
    setIsLogoHovered(true);
    setIsSidebarOpen(true);
  };

  const handleLogoMouseLeave = () => {
    setIsLogoHovered(false);
    timeoutId = setTimeout(() => {
      if (!isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    }, 50);
  };

  return (
    <UniqueIdProvider>
      <Router>
        <LocationTracker /> {/* Ce composant utilise useLocation */}

        <div
          className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
          ref={sidebarRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="sidebar-content">
            <Link to="/mentions-legales">
              <p><i className="fa fa-edit" aria-hidden="true"></i> Mentions légales</p>
            </Link>
          </div>
          <ChatbotAssistant />
        </div>
        <Logo
          setIsSidebarOpen={setIsSidebarOpen}
          onMouseEnter={handleLogoMouseEnter}
          onMouseLeave={handleLogoMouseLeave}
        />
        <Routes>
          <Route path="/" element={<CategoryList />} />
          <Route path="/category/:id" element={<CategoryPage />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
        </Routes>
      </Router>
    </UniqueIdProvider>
  );
};

// Ce composant va maintenant utiliser useLocation dans le bon contexte
const LocationTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);

  return null; // Ce composant ne rend rien d'autre
};

export default App;
import React from 'react';

const ProgressBar = ({ loading }) => {
  return loading ? (
    <div className="progress-bar-container">
      <div className="progress-bar"></div>
    </div>
  ) : null;
};

export default ProgressBar;

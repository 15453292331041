import React, { useState, useEffect, useRef } from 'react';
import '../App.css'; // Import the CSS file for styling
import ReactMarkdown from 'react-markdown';
const ChatbotAssistant = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    // Add user message to the chat
    setMessages([...messages, { text: input, sender: 'user' }]);
    setInput('');
    setLoading(true);

    // Simulate bot response (replace with actual API call)
    try {
      const response = await fetch('https://backend-service-858437566769.europe-west1.run.app/api/advanced_search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: input }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessages((prevMessages) => [ 
          ...prevMessages,
          { text: data.response, sender: 'bot' },
        ]);
      } else {
        console.error("Error searching bots:", await response.text());
      }
    } catch (error) {
      console.error("Error searching bots:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when new messages are added
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className={`chatbot-assistant ${isOpen ? 'open' : ''}`}>
      {/*<div className="chatbot-toggle" onClick={handleToggle}>
        <div className="chatbot-bubble">💬</div>
      </div>*/}
      {isOpen && (
        <div className="chatbot-window">
          <div className="chatbot-messages">
            {messages.length === 0 ? (
              <span className='chatbot-title'>Posez votre question pour trouver un bot ou chercher sur le site</span>
            ) : (
              messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
            {loading && <div className="spinner"><i className="fas fa-spinner fa-spin"></i></div>}
          </div>
          <div className="chatbot-input">
            <textarea
              rows={3}
              value={input}
              onChange={handleInputChange}
              placeholder="Posez votre question..."
            />
            <button onClick={handleSendMessage}>Envoyer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotAssistant;
